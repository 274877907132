export const imagesCars = [
  {
    original: "photos/cars/01-002.jpg",
    thumbnail: "photos/cars/01-002.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/01-005.jpeg",
    thumbnail: "photos/cars/01-005.jpeg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/01-001.jpg",
    thumbnail: "photos/cars/01-001.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/01-03.jpg",
    thumbnail: "photos/cars/01-03.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/01-04.jpg",
    thumbnail: "photos/cars/01-04.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/01.jpg",
    thumbnail: "photos/cars/01.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/01-02.jpg",
    thumbnail: "photos/cars/01-02.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/02.jpg",
    thumbnail: "photos/cars/02.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/03.jpg",
    thumbnail: "photos/cars/03.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/04.jpg",
    thumbnail: "photos/cars/04.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/05.jpg",
    thumbnail: "photos/cars/05.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/06.jpg",
    thumbnail: "photos/cars/06.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/07.jpg",
    thumbnail: "photos/cars/07.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/08.jpg",
    thumbnail: "photos/cars/08.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/09.jpg",
    thumbnail: "photos/cars/09.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/10.jpg",
    thumbnail: "photos/cars/10.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/11.jpg",
    thumbnail: "photos/cars/11.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
];

